import React from 'react'
import styled from 'styled-components'

const Round = ({position, radius, opacity, bold = 30}) => {
    const RoundElement = styled.div`
        position: absolute;
        left: ${`${position[0]}rem`};
        top: ${`${position[1]}rem`};
        width: ${`${radius}rem`};
        height: ${`${radius}rem`};
        border: ${`${bold}px`} solid ${props => `rgba(${props.theme.textGreenRgba}, ${opacity})`};
        border-radius: 50%;
        z-index: 1;
    `
    return (
      <RoundElement>
      </RoundElement>
    )
  }
export default Round