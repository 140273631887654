import React from 'react'
import styled from 'styled-components'
import logoIoa from '../../assets/partners/logo-ioa.svg'
import logoGakeslab from '../../assets/partners/logo-gakeslab.svg'
import TopTitle from '../TopTitle'
import Round from '../elements/Round'
import Plus from '../elements/Plus'

const Section = styled.section`
  min-height: 85vh;
  width: 100vw;
  position: relative;
  background-image: linear-gradient(${props => props.theme.backgroundLightGreen} 50%, white);

  @media (max-width: 30em){
    min-height: 90vh;
  }
`

const Container = styled.div`
  width: 50%;
  min-height: 70vh;
  margin: 0 auto;

  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 64em){
    width: 80%;
  }

  @media (max-width: 48em){
    width: 90%;
    justify-content: center;
    flex-direction: column;
  }
`

const Box = styled.div`
  width: 60%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 10;

  @media (max-width: 64em){
    width: 80%;
    align-self: center;
  }
`

const PartnerContainer = styled.div`
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: all 0.2s ease;

    &:hover{
      transform: scale(1.05);
    }

    a{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    p{
        font-size: ${props => props.theme.fontlg1};
        text-transform: capitalize;
        color: ${props => props.theme.textBlack};
        font-weight: 600;
        text-align: center;
    }

    @media (max-width: 64em){
      margin: 1rem 0;
        p{
          font-size: ${props => props.theme.fontmd};
        }
    }

    @media (max-width: 30em){
      img{
        width: 90%;
      }
    }

    @media (max-width: 20em){
        p{
          font-size: 0.9em;
        }
    }
  
`

const Partner = ({src, name, link}) => {
    return(
      <PartnerContainer>
        <a href={link} target="_blank" rel="noopener noreferrer">
          <img src={src} alt={name}/>
          <p>{name}</p>
        </a>
      </PartnerContainer>
    )
  }

const Partners = () => {
  return (
    <Section id="partners">
        <TopTitle title={["Our ", "Partners"]}/>
        <Container>
            <Box> <Partner src={logoIoa} name="Indonesia Orthopaedic Association (IOA)" link="https://indonesia-orthopaedic.org/"/></Box>
            <Box> <Partner src={logoGakeslab} name="GAKESLAB Indonesia" link="https://gakeslabindonesia.id/"/></Box>

            <Round position={[-5, 1]} radius="10" opacity="0.3"/>
            <Round position={[85, 30]} radius="10" opacity="0.3"/>

            <Plus position={[15, 10]} isGreen={1}/>

            <Plus position={[75, 5]} isGreen={0}/>
            <Plus position={[10, 35]} isGreen={0}/>


        </Container>
    </Section>
  )
}

export default Partners