import React from "react";
import styled from "styled-components";
import Button from "./Button";

const Title = styled.h2`
  font-size: ${(props) => props.theme.fontxxl};
  text-tranform: capitalize;
  width: 90%;
  color: ${(props) => props.theme.textGreen};
  align-self: flex-start;
  font-weight: 600;
  margin-bottom: 2rem;
  -webkit-text-stroke: 1px ${(props) => props.theme.textGreen};
  line-height: 4rem;

  span {
    border-bottom: 12px solid ${(props) => props.theme.underline};
    display: inline-block;
    line-height: 0.6;
  }

  @media (max-width: 75em) {
    font-size: 2.2em;
  }

  @media (max-width: 64em) {
    width: 95%;
    font-size: 2em;
    align-self: center;
    text-align: center;
  }

  @media (max-width: 30em) {
    font-size: 1.6em;
    width: 90%;
  }

  @media (max-width: 20em) {
    font-size: 1.5em;
    width: 90%;
  }
`;

const SubTitle = styled.h3`
  font-size: ${(props) => props.theme.fontmd};
  color: ${(props) => props.theme.textBlack};
  font-weight: 400;
  margin-bottom: 2rem;
  width: 100%;
  align-self: flex-start;
  line-height: 1.5rem;

  @media (max-width: 30em) {
    font-size: 1em;
  }

  @media (max-width: 20em) {
    font-size: 0.9em;
  }

  @media (max-width: 64em) {
    align-self: center;
    text-align: center;
  }
`;
const ButtonContainer = styled.div`
  width: 80%;
  align-self: flex-start;

  @media (max-width: 64em) {
    align-self: center;
    text-align: center;

    button {
      margin: 0 auto;
    }
  }
`;

const BigText = ({ title, subtitle, buttonText }) => {
  return (
    <>
      <Title>
        {title[0]}
        <span>{title[1]}</span>
        {title[2]}
      </Title>
      <SubTitle>{subtitle}</SubTitle>
      <ButtonContainer>
        <Button text={buttonText} />
      </ButtonContainer>
    </>
  );
};

export default BigText;
