import React from 'react'
import styled from 'styled-components'
import BigPicture from '../BigPicture'
import MediumText from '../MediumText'
import TopTitle from '../TopTitle'
import featureImage1 from '../../assets/features/feature-image-1.png'
import featureImage2 from '../../assets/features/feature-image-2.png'
import featureImage3 from '../../assets/features/feature-image-3.png'
import featureImage4 from '../../assets/features/feature-image-4.png'
import Plus from '../elements/Plus'
import Round from '../elements/Round'

const Section = styled.section`
  min-height: 100vh;
  width: 100vw;
  position: relative;
  background-image: linear-gradient(white 1%, ${props => props.theme.backgroundLightGreen});

  @media (max-width: 64em){
    
    &>*:nth-of-type(2n+1){
      flex-direction: column;
    }

    &>*:nth-of-type(2n){
      flex-direction: column-reverse;
    }
  }

  @media (max-width: 64em){
    padding-bottom: 5rem;
  }

  @media (max-width: 30em){
    padding-bottom: 3rem;
  }
  
`

const Container = styled.div`
  width: 80%;
  min-height: 85vh;
  margin: 0 auto;

  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 64em){
    min-height: 80vh;
  }

  @media (max-width: 48em){
    min-height: 70vh;
    
    &>*:first-child{
      width: 100%;
      margin-top: 0.5rem;
    }
  }
  
  @media (max-width: 30em){
    margin-top: 1.75rem;
  }

  
`

const Box = styled.div`
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 10;
  @media (max-width: 64em){
    width: 85%;
  }
`
const Features = () => {
  return (
    <Section id="features">
        <TopTitle title={["Our ", "Features"]}/>
        <Container>
            <Box><BigPicture src={featureImage1} alt="Feature 1"/></Box>
            <Box>
                <MediumText title={['Workflow ', 'Automation', '']}
                    subtitle="Accelerate staff productivity by automating any manual task such as member registration (ex: PPDS, journal, events), member approvals (P2KB, hospital transfer, benefit redemption), and many more"
                    buttonText="Get a Demo"/>
            </Box>
        </Container>

        <Container>
            <Box>
                <MediumText title={['User ', 'Independent', ' Access']}
                    subtitle="We provide one app for you to manage your operations from anywhere, and one app for your members to stay connected"
                    buttonText="Get a Demo"/>
            </Box>
            <Box><BigPicture src={featureImage2} alt="Feature 2"/></Box>
        </Container>

        <Container>
            <Box><BigPicture src={featureImage3} alt="Feature 3"/></Box>
            <Box>
                <MediumText title={['', 'Smart', ' Reminder']}
                    subtitle="Send renewal reminders through a centralized membership database, automated emails, and push notifications"
                    buttonText="Get a Demo"/>
            </Box>
        </Container>

        <Container>
            <Box>
                <MediumText title={['Dashboard & ', 'Advanced', ' Reporting']}
                    subtitle="Customizable landing page where members can learn about the organization and register for their membership"
                    buttonText="Get a Demo"/>
            </Box>
            <Box><BigPicture src={featureImage4} alt="Feature 4"/></Box>
        </Container>

        <Round position={[-5, 40]} radius="10" opacity="0.4"/>

        <Plus position={[80, 7]} isGreen={1}/>
        <Plus position={[52, 15]} isGreen={1}/>
        <Plus position={[80, 40]} isGreen={0}/>

        <Plus position={[15, 55]} isGreen={1}/>
        <Plus position={[40, 82]} isGreen={0}/>

        <Round position={[85, 90]} radius="10" opacity="0.4"/>

        <Plus position={[75, 100]} isGreen={1}/>
        <Plus position={[50, 125]} isGreen={0}/>

        <Round position={[-7, 130]} radius="10" opacity="0.4"/>
        <Plus position={[15, 140]} isGreen={1}/>
        <Plus position={[40, 165]} isGreen={0}/>

        <Round position={[110, 225]} radius="10" opacity="0.4"/>

    </Section>
  )
}

export default Features