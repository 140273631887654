import React from "react";
import styled from "styled-components";
import line from "../../assets/icons/line.svg";
import Button from "../Button";
import Plus from "../elements/Plus";
import Round from "../elements/Round";

const Section = styled.section`
  min-height: 50vh;
  width: 100vw;
  position: relative;
  background-color: ${(props) => props.theme.backgroundLightGreen};
  background: linear-gradient(
      to right,
      rgba(65, 163, 108, 0.4),
      rgba(158, 231, 189, 0.4),
      rgba(216, 252, 232, 0.9)
    ),
    url(${line});
  background-repeat: no-repeat;
  background-position: center center;

  background-size: 100%;
`;

const Container = styled.div`
  width: 90%;
  height: 50vh;
  margin: auto 0;

  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;

  @media (max-width: 64em) {
    width: 100%;
    justify-content: center;
    flex-direction: column;
  }
`;

const Title = styled.h2`
  font-size: ${(props) => props.theme.fontxxl};
  text-tranform: capitalize;
  color: ${(props) => props.theme.textGreen};
  font-weight: 600;
  -webkit-text-stroke: 1px ${(props) => props.theme.textGreen};
  text-align: center;
  letter-spacing: 0.03em;

  span {
    border-bottom: 10px solid ${(props) => props.theme.underline};
    display: inline-block;
    line-height: 0.6;
  }

  @media (max-width: 40em) {
    font-size: ${(props) => props.theme.fontxl};
  }

  @media (max-width: 30em) {
    font-size: 1.7em;
  }

  @media (max-width: 20em) {
    font-size: 1.5em;
  }
`;

const TitleContainer = styled.div`
  width: 80%;
`;

const ButtonContainer = styled.div`
    width: 20%;

    @media (max-width: 64em){
        display: flex;
        justify-content: center;
        align-items: center;
        width: 50%;
        margin 2rem auto;
    }
`;

const GetADemo = () => {
  return (
    <Section id="getademo">
      <Container>
        <TitleContainer>
          <Title>
            Minimum Busyworks, <span>Maximum Impact</span>
          </Title>
        </TitleContainer>
        <ButtonContainer>
          <Button text="Get a Demo" />
        </ButtonContainer>

        <Plus position={[60, 5]} isGreen={1} />
        <Plus position={[25, 20]} isGreen={0} />

        <Round position={[85, 17]} radius="8" opacity="0.3" bold={60} />
      </Container>
    </Section>
  );
};

export default GetADemo;
