import React from "react";
import styled from "styled-components";
import useAnalyticsEventTracker from "./utils/useAnalyticsEventTracker";

const Button = ({
  text,
  link = "https://wa.me/6289509644010?text=Hai Admin, info dari Web Organify. Saya tertarik untuk diskusi terkait Organify.",
  isBlock = 0,
}) => {
  const Btn = styled.button`
    display: ${isBlock === 0 ? "inline-block" : "block"};
    ${isBlock === 1 &&
    `
        width: 100%;
      `}
    background-color: ${(props) => props.theme.textGreen};
    color: ${(props) => props.theme.backgroundWhite};
    outline: none;
    border: none;

    font-family: ${(props) => props.theme.fontFamily};
    font-size: ${(props) => props.theme.fontmd};
    font-weight: 600;
    padding: 1rem 1.2rem;
    border-radius: 10px;
    cursor: pointer;
    transition: all 0.2s ease;
    position: relative;

    letter-spacing: 0.03em;

    @media (max-width: 30em) {
      font-size: 1em;
      padding: 0.75rem 1.3rem;
    }

    &:hover {
      // transform: scale(0.98);

      transition: 0.3s ease-in-out;
      box-shadow: 0 0 10px ${(props) => props.theme.textGreen};
    }

    // &::after{
    //     content: ' ';
    //     position: absolute;
    //     top: 50%;
    //     left: 50%;
    //     transform: translate(-50%, -50%) scale(0);
    //     border: 2px solid ${(props) => props.theme.textGreen};
    //     width: 100%;
    //     height: 100%;
    //     border-radius: 10px;
    //     transition: all 0.2s ease;
    // }

    // &:hover::after{
    //     transform: translate(-50%, -50%) scale(1);
    //     padding: 0.3rem;
    // }
  `;

  const gaEventTracker = useAnalyticsEventTracker("Contact us");

  return (
    <Btn>
      <a
        href={link}
        aria-label={text}
        target="_blank"
        rel="noreferrer"
        onClick={() => gaEventTracker(`${text}`)}
      >
        {text}
      </a>
    </Btn>
  );
};

export default Button;
