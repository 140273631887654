import { ThemeProvider } from "styled-components";
import Footer from "./components/Footer";
// import Navigation from "./components/Navigation";
import NewNavigation from "./components/NewNavigation";
import Company from "./components/sections/Company";
import Features from "./components/sections/Features";
import GetADemo from "./components/sections/GetADemo";
import Partners from "./components/sections/Partners";
import Pricing from "./components/sections/Pricing";
import Product from "./components/sections/Product";
// import Testimonials from './components/sections/Testimonials';
import StickyContact from "./components/StickyContact";
import GlobalStyles from "./styles/GlobalStyles";
import { light } from "./styles/Themes";

// import ReactGA from 'react-ga';
import React from "react";
import ReactGA from "react-ga4";
// import { useEffect } from 'react';
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ScrollToTop from "./components/ScrollToTop";
import { availableRoutes } from "./components/utils/availableRoutes";
const TRACKING_ID = "G-9Q5BEF5KRL"; // OUR_TRACKING_ID
// ReactGA.initialize(TRACKING_ID);

function App() {
  let loc = useLocation();
  let nav = useNavigate();
  // useEffect(() => {
  //   ReactGA.pageview(window.location.pathname + window.location.search);
  // }, []);

  // console.log({ loc });
  ReactGA.initialize(TRACKING_ID);
  ReactGA.send({ hitType: "pageview", page: "/" });

  useEffect(() => {
    const id = loc.hash.split("#");

    if (id.length < 2) return;
    if (!availableRoutes.includes(id[1])) {
      nav("/");
      return;
    }

    let element = document.getElementById(id[1]);

    element.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest",
    });
  }, [loc.hash, nav]);

  return (
    <>
      <GlobalStyles />
      <ThemeProvider theme={light}>
        <NewNavigation />
        <Product />
        <Partners />
        <Features />
        <Pricing />
        {/* <Testimonials/> */}
        <Company />
        <GetADemo />
        <Footer />

        <StickyContact />
        <ScrollToTop />
      </ThemeProvider>
    </>
  );
}

export default App;
