import { useState } from "react";
import styled from "styled-components";
import Hamburger from "../assets/icons/burger.png";
import Close from "../assets/icons/close.png";
import Logo from "./Logo";

const Navbar = styled.nav`
  background-color: ${(props) => props.theme.backgroundLightGreen};
  padding: 19px 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1440px;
  margin: 0 auto;

  @media (min-width: 700px) {
    padding: 20px 100px;
  }

  @media (min-width: 900px) {
    padding: 22px 140px;
  }
`;

const LogoContainer = styled.div`
  z-index: 20;
  height: 65px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const Hidden = styled.div`
  z-index: 100;

  @media (min-width: 1150px) {
    display: none;
  }
`;

const Links = styled.div`
  display: none;

  @media (min-width: 1150px) {
    display: block;
  }
`;

const Li = styled.li`
  list-style: none;

  &:not(:first-child) {
    margin-left: 20px;
  }
`;

const NavLink = styled.a`
  text-decoration: none;
  font-size: 18px;
  font-weight: 700;
  color: #389d64;
  position: relative;
  &::after {
    content: "";
    position: absolute;
    bottom: -5px;
    left: 0;
    height: 5px;
    background-color: #f97206;
    width: 0;
    transition: all 0.5s ease;
  }
  &:hover::after {
    width: 100%;
  }
  &.active {
    border-bottom: 5px solid #f97206;
  }
`;

const GetDemo = styled.a`
  font-size: 20px;
  padding: 14px 20px;
  background-color: #389d64;
  color: white !important;
  font-weight: 600 !important;
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.15s ease;
  text-align: center;
  &:hover {
    box-shadow: 0 0 2px 2px #389d64;
  }

  &::after {
    display: none;
  }

  @media (min-width: 1150px) {
    font-size: 16px;
  }
`;

// Mobile
const MobileNav = styled.nav`
  height: 100vh;
  background-color: #ebfeeb;
  z-index: 15;
  animation: slideIn 0.2s ease-in forwards;
  position: absolute;
  top: 0rem;
  left: 0;
  right: 0;
`;

const MobileLinks = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding-left: 0;
`;

const MobileNavLink = styled.a`
  text-decoration: none;
  text-align: center;
  font-size: 24px;
  font-weight: 700;
  margin-top: 40px;
  display: block;
  color: #389d64;
  &.active {
    border-bottom: 5px solid #f97206;
  }
`;

const Mobile = ({ onToggleMenuOpen }) => {
  return (
    <MobileNav>
      <div className={"link"}>
        <MobileLinks>
          <li style={{ listStyle: "none" }}>
            <MobileNavLink href="#products">Products</MobileNavLink>
          </li>
          <li style={{ listStyle: "none" }}>
            <MobileNavLink href="#partners">Partners</MobileNavLink>
          </li>
          <li style={{ listStyle: "none" }}>
            <MobileNavLink href="#features">Features</MobileNavLink>
          </li>
          <li style={{ listStyle: "none" }}>
            <MobileNavLink href="#pricing">Pricing</MobileNavLink>
          </li>
          <li style={{ listStyle: "none" }}>
            <MobileNavLink href="#company">Company</MobileNavLink>
          </li>
          <li style={{ listStyle: "none" }}>
            <MobileNavLink href="https://articles.organify.id">
              Articles
            </MobileNavLink>
          </li>
          <li style={{ listStyle: "none", marginTop: "48px" }}>
            <GetDemo href="#getademo" className={"getDemoButton"}>
              Get a Demo
            </GetDemo>
          </li>
        </MobileLinks>
      </div>
    </MobileNav>
  );
};

export default function NewNavigation() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenuOpen = () => {
    setIsMenuOpen((prevMenu) => !prevMenu);
  };

  // const router = useRouter();

  return (
    <>
      <Navbar id="nav">
        <LogoContainer>
          <Logo />
        </LogoContainer>

        {!isMenuOpen ? (
          <Hidden>
            <img
              src={Hamburger}
              alt="menu"
              height={25}
              width={25}
              onClick={toggleMenuOpen}
            />
          </Hidden>
        ) : (
          <Hidden>
            <img
              src={Close}
              alt="close"
              height={25}
              width={25}
              onClick={toggleMenuOpen}
            />
          </Hidden>
        )}

        {isMenuOpen && <Mobile onToggleMenuOpen={toggleMenuOpen} />}

        {/* Revision */}
        <Links>
          <ul style={{ display: "flex" }}>
            <Li>
              <NavLink href="#product">Products</NavLink>
            </Li>
            <Li>
              <NavLink href="#partners">Partners</NavLink>
            </Li>
            <Li>
              <NavLink href="#features">Features</NavLink>
            </Li>
            <Li>
              <NavLink href="#pricing">Pricing</NavLink>
            </Li>
            <Li>
              <NavLink href="#company">Company</NavLink>
            </Li>
            <Li>
              <NavLink href="https://articles.organify.id">Articles</NavLink>
            </Li>
            <Li>
              <GetDemo href="#getademo" className={"getDemoButton"}>
                Get a Demo
              </GetDemo>
            </Li>
          </ul>
        </Links>

        {/* Revision */}
      </Navbar>
    </>
  );
}
