export const light = {

    backgroundWhite:"#fff",
    backgroundLightGreen:"#EBFEEB",
    textBlack:"#303030",
    textGreen:"#389D64",
    textOrange: '#F97206',
    underline:"#F3AA62",

    textGreenRgba: "56, 157, 100",
    textBlackRgba: "0, 0, 0",

    fontFamily: "'Plus Jakarta Sans', sans-serif",

    fontxs: '0.75em',
    fontsm: '0.875em',
    fontmd: '1.1em', // 1em = 16px
    fontlg1: '1.25em',
    fontlg2: '1.4em',
    fontxl: '2em',
    fontxxl: '2.7em',
    fontxxxl: '3em',

    fontButton: '0.875em',

    navHeight: '6rem'
}