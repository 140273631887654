import React from "react";
import styled from "styled-components";
import Button from "./Button";

const Title = styled.h2`
  font-size: ${(props) => props.theme.fontxxl};
  text-tranform: capitalize;
  width: 80%;
  color: ${(props) => props.theme.textBlack};
  align-self: center;
  font-weight: 900;
  margin-bottom: 2rem;
  -webkit-text-stroke: 1px ${(props) => props.theme.textBlack};

  span {
    color: ${(props) => props.theme.textGreen};
    -webkit-text-stroke: 1px ${(props) => props.theme.textGreen};
    border-bottom: 10px solid ${(props) => props.theme.underline};
    display: inline-block;
    line-height: 0.6;
  }

  @media (max-width: 70em) {
    font-size: 1.8em;
  }

  @media (max-width: 48em) {
    align-self: center;
    text-align: center;
  }

  @media (max-width: 40em) {
    width: 90%;
  }

  @media (max-width: 30em) {
    font-size: 1.6em;
    width: 100%;
  }
`;

const SubTitle = styled.h3`
  font-size: ${(props) => props.theme.fontmd};
  color: ${(props) => props.theme.textBlack};
  font-weight: 400;
  margin-bottom: 2rem;
  width: 80%;
  align-self: center;
  line-height: 20pt;

  @media (max-width: 40em) {
    font-size: 1em;
  }

  @media (max-width: 48em) {
    align-self: center;
    text-align: center;
  }

  @media (max-width: 30em) {
    width: 100%;
  }
`;
const ButtonContainer = styled.div`
  width: 80%;
  align-self: center;

  @media (max-width: 48em) {
    align-self: center;
    text-align: center;

    button {
      margin: 0 auto;
    }
  }
`;

const MediumText = ({ title, subtitle, buttonText }) => {
  return (
    <>
      <Title>
        {title[0]}
        <span>{title[1]}</span>
        {title[2]}
      </Title>
      <SubTitle>{subtitle}</SubTitle>
      <ButtonContainer>
        <Button text={buttonText} />
      </ButtonContainer>
    </>
  );
};

export default MediumText;
