import React from 'react'
import styled from 'styled-components'

const Title = styled.h2`
    font-size: ${props => props.theme.fontxl};
    text-tranform: capitalize;
    width: 80%;
    color: ${props => props.theme.textGreen};
    align-self: flex-start;
    font-weight: 900;
    margin: 0 auto;
    padding-top: 3rem;
    -webkit-text-stroke: 1px ${props => props.theme.textGreen};
    text-align: center;
    letter-spacing: 0.03em;

    span{
        border-bottom: 10px solid ${props => props.theme.underline};
        display: inline-block;
        line-height: 0.6;
    }

    @media (max-width: 30em){
      font-size: 1.7em;
    }
`

const TopTitle = ({title}) => {
  return (
    <>
      <Title>{title[0]}<span>{title[1]}</span>{title[2]}</Title>
    </>
  )
}

export default TopTitle