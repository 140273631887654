import React from "react";
import styled from "styled-components";
import contact from "../assets/icons/contact.svg";
import TooltipProvider from "./providers/TooltipProvider";

const Container = styled.div`
  width: 7rem;
  height: 3rem;

  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: ${(props) => props.theme.text};
  background-color: ${(props) => props.theme.body};

  font-size: ${(props) => props.theme.fontxl};
  position: fixed;
  right: 0.5rem;
  bottom: 2rem;

  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;

  z-index: 100;

  border-radius: 50%;
  transition: all 0.2s ease;

  @media (max-width: 30em) {
    width: 5rem;
    right: 0rem;
    bottom: 1rem;
  }
`;

const Img = styled.div`
  width: 4rem;
  padding: 0 1rem;
  cursor: pointer;
  transition: all 0.2s ease;
  filter: drop-shadow(2px 2px 2px rgb(0 0 0 / 0.3));

  img {
    width: 100%;
    height: auto;
  }

  &:hover {
    transform: scale(1.1);
  }

  &:active {
    transform: scale(0.9);
  }

  @media (max-width: 30em) {
    padding: 0 0.5rem;
    img {
      width: 90%;
      height: auto;
    }
  }
`;
const StickyContact = () => {
  return (
    <TooltipProvider label="Contact us" offsetVal={0}>
      <Container>
        <Img>
          <a
            href="https://wa.me/6289509644010?text=Hai Admin, info dari Web Organify. Saya tertarik untuk diskusi terkait Organify."
            aria-label="Contact Us"
            target="_blank"
            rel="noreferrer"
          >
            <img src={contact} alt="contact"></img>
          </a>
        </Img>
      </Container>
    </TooltipProvider>
  );
};

export default StickyContact;
