import React from "react";
import styled from "styled-components";
import featureBenefitIcon from "../../assets/icons/feature-benefit-icon.svg";
import Button from "../Button";
import Plus from "../elements/Plus";
import Round from "../elements/Round";
import TopTitle from "../TopTitle";

const Section = styled.section`
  min-height: 100vh;
  width: 100vw;
  padding: 4rem 0;
  position: relative;
  // background-color: ${(props) => props.theme.backgroundLightGreen};
  background-image: linear-gradient(
    ${(props) => props.theme.backgroundLightGreen} 50%,
    white
  );
`;

const Container = styled.div`
  width: 90%;
  min-height: 50vh;
  margin: 1.5rem auto;

  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 64em) {
    width: 80%;
    justify-content: center;
    flex-direction: column;
  }

  @media (max-width: 48em) {
    width: 90%;
    justify-content: center;
    flex-direction: column;
  }
`;

const Description = styled.p`
  font-size: ${(props) => props.theme.fontmd};
  color: ${(props) => props.theme.textBlack};
  font-weight: 400;
  margin: 2rem auto;

  width: 50%;
  align-self: flex-start;
  line-height: 20pt;
  text-align: center;

  @media (max-width: 64em) {
    width: 80%;
    font-size: 1em;
  }
`;

const Box = styled.div`
  width: 65%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: 64em) {
    width: 100%;
  }
`;

const Item = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  height: 70vh;
  width: 80vw;
  // padding: 2.5rem 1rem;
  background-color: ${(props) => props.theme.backgroundWhite};
  color: ${(props) => props.theme.body};
  margin: 1rem 1rem;
  position: relative;
  z-index: 5;
  cursor: pointer;

  overflow: hidden;

  border-radius: 20px;
  box-shadow: 0 3px 15px ${(props) => props.theme.textGreen};

  transition: all 0.3s ease;

  &:hover {
    transition: 0.3s ease-in-out;
  }

  @media (max-width: 64em) {
    flex-direction: column;
    height: 95vh;
  }

  @media (max-width: 55em) {
    flex-direction: column;
    height: 50vh;
  }

  @media (max-width: 48em) {
    flex-direction: column;
    height: 65vh;
  }

  @media (max-width: 30em) {
    flex-direction: column;
    height: 110vh;
  }

  @media (max-width: 30em) and (min-height: 600px) {
    flex-direction: column;
    height: 100vh;
  }

  @media (max-width: 30em) and (min-height: 700px) {
    flex-direction: column;
    height: 80vh;
  }

  @media (max-width: 20em) {
    flex-direction: column;
    height: 120vh;
  }

  @media (max-height: 750px) {
    height: 80vh;
  }

  @media (max-height: 600px) {
    height: 100vh;
  }
`;

const Benefit = styled.div`
  display: flex;
  justify-content: flex-start;
  align-self: flex-start;
  margin: 1.2rem 0;

  img {
    width: 2rem;
    height: auto;
    align-self: flex-start;
    margin-right: 0.5rem;
  }

  h1 {
    font-size: ${(props) => props.theme.fontmd};
    color: ${(props) => props.theme.textBlack};
    font-weight: 500;
    letter-spacing: 0.03em;
    align-self: center;

    @media (max-width: 75em) {
      font-size: 1em;
    }

    @media (max-width: 30em) {
      font-size: 0.95em;
    }
  }

  @media (max-width: 64em) {
    padding: 0 2.5rem;
  }

  @media (max-width: 40em) {
    padding: 0 1.5rem 0 1rem;
  }
`;

const ButtonContainer = styled.div`
  width: 100%;
  align-self: center;
  margin-top: 3rem;

  @media (max-width: 64em) {
    width: 90%;
    margin: 2rem 0;
  }
`;

const Pricing = () => {
  return (
    <Section id="pricing">
      <TopTitle title={["", "Pricing", " Plans"]} />
      <Description>
        To help you decide if Organify is right for you we’ll schedule you a
        free demo, try all features, and discuss a perfect opportunity for your
        organization.{" "}
      </Description>
      <Container>
        <Item>
          <Box>
            <Benefit>
              <img src={featureBenefitIcon} alt="Icon Benefit" />
              <h1>
                Perfect for small to medium-sized organizations that need
                automation to reduce manual tasks
              </h1>
            </Benefit>
            <Benefit>
              <img src={featureBenefitIcon} alt="Icon Benefit" />
              <h1>
                Available Access to All organify Platforms (Public Access Web
                and Back Office Web)
              </h1>
            </Benefit>
            <Benefit>
              <img src={featureBenefitIcon} alt="Icon Benefit" />
              <h1>One organization get free access up to 1000 Members</h1>
            </Benefit>
            <Benefit>
              <img src={featureBenefitIcon} alt="Icon Benefit" />
              <h1>Free Member Mobile App</h1>
            </Benefit>
            <Benefit>
              <img src={featureBenefitIcon} alt="Icon Benefit" />
              <h1>Unlimited Admin Seats Included</h1>
            </Benefit>

            <ButtonContainer>
              <Button text="Get a Demo" isBlock={1} />
            </ButtonContainer>

            <Round position={[-5, -5]} radius="9" opacity="0.25" />
            <Round position={[60, 37]} radius="9" opacity="0.25" />

            <Round position={[3, 35]} radius="3" opacity="0.1" />
            <Round position={[57, 5]} radius="3" opacity="0.1" />
          </Box>
        </Item>
        <Round position={[-6, -5]} radius="10" opacity="0.3" />
      </Container>

      <Plus position={[20, 10]} isGreen={1} />
      <Plus position={[80, 15]} isGreen={1} />
      <Plus position={[75, 50]} isGreen={0} />
      <Plus position={[15, 52]} isGreen={0} />
    </Section>
  );
};

export default Pricing;
