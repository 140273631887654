import React from "react";
import styled from "styled-components";
import Button from "../Button";
import Plus from "../elements/Plus";
import Round from "../elements/Round";
import TopTitle from "../TopTitle";

const Section = styled.section`
  min-height: 90vh;
  width: 100vw;
  position: relative;
  background-image: linear-gradient(
    white 1%,
    ${(props) => props.theme.backgroundLightGreen}
  );

  @media (max-width: 30em) {
    padding-bottom: 6rem;
  }

  @media (max-width: 64em) {
    min-height: 90vh;
  }

  @media (max-width: 55em) {
    min-height: 70vh;
  }

  @media (max-width: 55em) and (max-height: 1000px) {
    min-height: 100vh;
  }
`;

const Container = styled.div`
  width: 80%;
  height: 60vh;
  margin: 0 auto;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  @media screen and (max-width: 48em) and (max-height: 1000px) {
    height: 90vh;
  }

  @media screen and (max-width: 48em) and (max-height: 900px) {
    height: 100vh;
  }

  @media screen and (max-width: 48em) and (max-height: 800px) {
    height: 120vh;
  }

  @media screen and (max-width: 48em) and (max-height: 700px) {
    height: 130vh;
  }

  @media (max-width: 80em) {
    height: 70vh;
  }

  @media (max-width: 64em) {
    height: 70vh;
  }

  @media (max-width: 55em) {
    height: 50vh;
  }

  @media (max-width: 55em) and (max-height: 1000px) {
    height: 80vh;
  }

  @media (max-width: 30em) and (max-height: 900px) {
    height: 110vh;
  }

  @media (max-width: 30em) and (max-height: 800px) {
    height: 90vh;
  }

  @media (max-width: 30em) and (max-height: 700px) {
    height: 130vh;
  }

  @media (max-width: 30em) and (max-height: 600px) {
    height: 150vh;
  }

  @media (max-width: 20em) {
    height: 180vh;
  }

  @media (max-width: 80em) and (max-height: 750px) {
    height: 100vh;
  }
`;

const Description = styled.p`
  font-size: ${(props) => props.theme.fontlg1};
  color: ${(props) => props.theme.textBlack};
  font-weight: 400;
  margin: 5rem auto;

  width: 80%;
  align-self: flex-start;
  line-height: 2.5rem;
  text-align: center;

  @media (max-width: 64em) {
    width: 100%;
  }

  @media (max-width: 48em) {
    width: 85%;
    font-size: ${(props) => props.theme.fontmd};
  }

  @media (max-width: 30em) {
    width: 90%;
    font-size: 1em;
    margin: 3rem auto;
  }
`;

const ButtonContainer = styled.div`
  width: 80%;
  align-self: center;
  text-align: center;

  @media (max-width: 48em) {
    align-self: center;
    text-align: center;

    button {
      margin: 0 auto;
    }
  }
`;

const Company = () => {
  return (
    <Section id="company">
      <TopTitle title={["Our ", "Company", ""]} />
      <Container>
        <Description>
          Bigio is a technology consulting company that implements digital
          transformation through software product development and integration
          for organization and businesses. We are experts in web, mobile
          application, data science, UI/UX development, and tech consulting. We
          served more than 100+ sectors, including government, UMKM, non-profit,
          and health institutions. Organify is one from many digital products we
          produce in order to fulfil the customer need in this dynamic world. To
          see more of our portfolio project. Do not hesitate to visit our
          company website by clicking this button below.
        </Description>
        <ButtonContainer>
          <Button text="Get To Know Us" link="https://bigio.id/" />
        </ButtonContainer>

        <Plus position={[75, 5]} isGreen={1} />
        <Plus position={[20, 35]} isGreen={0} />

        <Round position={[-7, 35]} radius="8" opacity="0.3" bold={70} />
      </Container>
    </Section>
  );
};

export default Company;
