import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import logo from '../assets/logo/logo-header.png'

const LogoContainer = styled.div`
    transition: all 0.2s ease;

    &:hover{
        transform: scale(1.1);
    }
`

const Logo = () => {
  return (
    <LogoContainer>
        <Link to="/">
            <img src={logo} alt="Organify" />
        </Link>
    </LogoContainer>
  )
}

export default Logo