import React from 'react'
import styled from 'styled-components'
import BigPicture from '../BigPicture'
import BigText from '../BigText'
import Plus from '../elements/Plus'
import Round from '../elements/Round'
import productImage from '../../assets/product/product-image.png'

const Section = styled.section`
  min-height: 90vh;
  width: 100vw;
  position: relative;
  background-color: ${props => props.theme.backgroundLightGreen};

  @media (max-width: 30em){
    padding-bottom: 2.7rem;
  }
`

const Container = styled.div`
  width: 80%;
  min-height: 80vh;
  margin: 0 auto;
  // background-color: lightblue;

  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 64em){
    width: 85%;
  }

  @media (max-width: 64em){
    flex-direction: column-reverse;
    
    &>*:first-child{
      width: 100%;
      margin-top: 0.5rem;
    }
  }
`

const Box = styled.div`
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const Product = () => {
  return (
    <Section id="product">
        <Container>
            <Box>
              <BigText title={['Deliver Smart Member ', 'Management System', ' for Your Organization']}
                subtitle="Automate your productivity through Organify - the all in one platform that can help you streamline your member management process and improve staff efficiency."
                buttonText="Get a Demo"/> 
            </Box>
            <Box> <BigPicture src={productImage} alt="Product"/> </Box>

            <Round position={[-1.5, 1]} radius="6" opacity="0.3"/>
            <Round position={[80, 2]} radius="6" opacity="0.6"/>

            <Plus position={[40, 5]} isGreen={1}/>
            <Plus position={[30, 30]} isGreen={1}/>

            <Plus position={[10, 36]} isGreen={0}/>
            <Plus position={[80, 38]} isGreen={0}/>
        </Container>
    </Section>
  )
}

export default Product