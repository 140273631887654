import React from 'react'
import styled from 'styled-components'
import plusGreen from '../../assets/icons/plus-green.svg'
import plusOrange from '../../assets/icons/plus-orange.svg'

const Plus = ({position, isGreen = 1}) => {
    const PlusContainer = styled.div`
        position: absolute;
        left: ${`${position[0]}rem`};
        top: ${`${position[1]}rem`};
        z-index: 1;
    `
    if(isGreen === 1){
        return (
            <PlusContainer>
                <img src={plusGreen} alt="plus"/>
            </PlusContainer>
          )
    }else{
        return (
            <PlusContainer>
                <img src={plusOrange} alt="plus"/>
            </PlusContainer>
          )
    }
 
}

export default Plus