import {
  autoUpdate,
  offset,
  useDismiss,
  useFloating,
  useFocus,
  useHover,
  useInteractions,
  useRole,
} from "@floating-ui/react-dom-interactions";
import { cloneElement, useState } from "react";
import styled from "styled-components";

const Tooltip = styled.div`
  padding: 8px 14px;
  background: #515151;
  color: white;
  border-radius: 6px;
  box-shadow: "hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px";
  user-select: none;
  font-size: 12px;
  z-index: 99;
`;

export default function TooltipProvider({
  children,
  label,
  show = true,
  offsetVal,
}) {
  const [open, setOpen] = useState(false);

  const { x, y, reference, floating, strategy, context } = useFloating({
    placement: "left",
    open,
    onOpenChange: setOpen,
    strategy: "fixed",
    middleware: [offset(offsetVal ?? 4)],
    whileElementsMounted: autoUpdate,
  });

  const { getReferenceProps, getFloatingProps } = useInteractions([
    useHover(context),
    useFocus(context),
    useRole(context, { role: "tooltip" }),
    useDismiss(context),
  ]);

  // Preserve the consumer's ref
  // const ref = useMemo(
  //   () => mergeRefs([reference, children.ref]),
  //   [reference, children]
  // );

  return (
    <>
      {cloneElement(
        children,
        getReferenceProps({ ref: reference, ...children.props })
      )}
      {show && open && (
        <Tooltip
          {...getFloatingProps({
            ref: floating,
            style: {
              position: strategy,
              top: y ?? 0,
              left: x ?? 0,
            },
          })}
        >
          {label}
        </Tooltip>
      )}
    </>
  );
}
