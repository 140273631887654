import React, { useLayoutEffect, useRef, useState } from "react";
import { useWindowScroll } from "react-use";
import styled from "styled-components";
import scrollUp from "../assets/icons/scroll-up.svg";
import TooltipProvider from "./providers/TooltipProvider";

const Container = styled.div`
  width: 7rem;
  height: 3rem;

  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: ${(props) => props.theme.text};
  background-color: ${(props) => props.theme.body};

  font-size: ${(props) => props.theme.fontxl};
  position: fixed;
  right: 0.5rem;
  bottom: 6.5rem;

  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;

  z-index: 100;

  border-radius: 50%;
  transition: all 0.2s ease;

  @media (max-width: 30em) {
    width: 5rem;
    right: 0rem;
    bottom: 5rem;
  }
`;

const Img = styled.div`
  width: 3rem;
  padding: 0 1rem;
  cursor: pointer;
  transition: all 0.2s ease;
  filter: drop-shadow(3px 4px 2px rgb(0 0 0 / 0.2));

  img {
    width: 100%;
    height: auto;
  }

  &:hover {
    transform: scale(1.1);
  }

  &:active {
    transform: scale(0.9);
  }

  @media (max-width: 30em) {
    padding: 0 0.5rem;
    img {
      width: 90%;
      height: auto;
    }
  }
`;

const ScrollToTop = () => {
  const [showTooltip, setShowTooltip] = useState(false);
  const ref = useRef(null);
  const { y } = useWindowScroll();

  const scrollToTop = () => {
    let element = document.getElementById("nav");

    element.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest",
    });
  };

  useLayoutEffect(() => {
    if (y > 200) {
      ref.current.style.display = "flex";
      setShowTooltip(true);
    } else {
      ref.current.style.display = "none";
      setShowTooltip(false);
    }
  }, [y]);

  return (
    <TooltipProvider show={showTooltip} label="Back to top" offsetVal={0}>
      <Container>
        <Img ref={ref} onClick={() => scrollToTop()}>
          <img src={scrollUp} alt="contact"></img>
        </Img>
      </Container>
    </TooltipProvider>
  );
};

export default ScrollToTop;
