import React from 'react'
import styled from 'styled-components'

const ImageContainer = styled.div`
    width: 100%;
    z-index: 2;
    img{
        width: 100%;
        height: auto;
    }

    @media (max-width: 48em){
      margin-top: 2rem;
      min-width: 40vh;
    }
`
const BigPicture = ({src, alt}) => {
  return (
    <ImageContainer>
          <img src={src} alt={alt}/>
    </ImageContainer>
  )
}

export default BigPicture